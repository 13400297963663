import React from 'react'
import NextLink from 'next/link'
import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityFooterSettings } from '@data/sanity/queries/types/site'

import Menu from '@blocks/navigation/menu'
import Photo from '@components/photo'
import ComplexPortableText from '@components/complex-portable-text'
import SimplePortableText from '@components/simple-portable-text'
import Icon from '@components/icon'

type FooterProps = {
  settings?: SanityFooterSettings
  logo?: SanityImageFragment
}

const Footer = ({ settings, logo }: FooterProps) => (
  <footer
    className="relative overflow-hidden bg-footer-bg text-footer-text pt-28 pb-14"
    role="contentinfo"
  >
    <div className="container flex flex-col gap-y-16 sm:gap-y-28">
      <div className="flex flex-wrap items-start gap-y-14 gap-x-24 xl:gap-x-48">
        {settings?.contentBlocks?.map(({ title, content }, index) => (
          <div key={`footer-content-block-${index}`}>
            {title && <h3 className="mb-4">{title}</h3>}
            {content && (
              <div className="rc">
                <SimplePortableText content={content} />
              </div>
            )}
          </div>
        ))}

        {settings?.workingHours && (
          <div>
            {settings?.workingHours?.title && (
              <h3 className="mb-4">{settings.workingHours.title}</h3>
            )}

            <p className="grid grid-cols-2 gap-x-8">
              {settings.workingHours?.items.map(({ day, hours }, index) => (
                <React.Fragment key={`footer-working-hours-${index}`}>
                  <span>{day}</span>
                  <span>{hours}</span>
                </React.Fragment>
              ))}
            </p>
          </div>
        )}

        <div className="flex flex-wrap gap-14 md:gap-x-24">
          {settings?.menuBlocks?.map(({ title, menu }, index) => (
            <div
              key={`footer-menu-block-${index}`}
              className={cx(
                'flex flex-col gap-y-4',
                title ? 'justify-start' : 'justify-end'
              )}
            >
              {title && <h3>{title}</h3>}
              {menu && <Menu items={menu.items} isFooterMenu />}
            </div>
          ))}
        </div>
      </div>

      {settings?.social && (
        // TODO: Could use `Menu` component here
        <ul className="flex gap-x-4">
          {settings.social.map((link) => (
            <li key={link._key}>
              <a
                id={`footer-social-link-${link.socialNetwork?.toLowerCase()}`}
                className="rounded-full p-2.5 transition-all duration-200 inline-flex text-2xl text-left !no-underline uppercase border border-footer-text border-opacity-25 hover:border-opacity-100"
                key={link._key}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  id={`footer-social-link-${link.socialNetwork?.toLowerCase()}`}
                  name={`${link.socialNetwork}`}
                />
              </a>
            </li>
          ))}
        </ul>
      )}

      <Menu
        items={settings?.additionalMenu?.items ?? []}
        className="flex flex-wrap gap-y-2 gap-x-8 opacity-50 [&>li]:text-sm"
      />
    </div>
  </footer>
)

export default Footer
