import { type PortableTextComponentProps } from '@portabletext/react'

import { type SanityVideoEmbed } from '@data/sanity/queries/types/content'
import { getYouTubeVideoId } from '@lib/video'

type VideoEmbedValue = Pick<SanityVideoEmbed, 'type' | 'youtubeVideoUrl'>

const VideoEmbed = ({ value }: PortableTextComponentProps<VideoEmbedValue>) => {
  const { type, youtubeVideoUrl } = value

  switch (type) {
    case 'youtube': {
      if (!youtubeVideoUrl) {
        return null
      }

      const youtubeVideoId = getYouTubeVideoId(youtubeVideoUrl)

      if (!youtubeVideoId) {
        return null
      }

      return (
        <div className="relative w-full pt-[calc((9/16)*100%)]">
          <div className="absolute inset-0">
            <iframe
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title="YouTube video player"
              allow="encrypted-media"
              allowFullScreen
              width="100%"
              height="100%"
              className="border-0"
            ></iframe>
          </div>
        </div>
      )
    }

    default: {
      return null
    }
  }
}

export default VideoEmbed
