import { type SanityRestaurantMenuItem } from '@data/sanity/queries/types/blocks'

import Accordion from '@components/accordion'

interface RestaurantMenuCategoryProps {
  id: string
  title?: string
  items?: SanityRestaurantMenuItem[]
  className?: string
}

const RestaurantMenuCategory = ({
  id,
  title,
  items,
  className,
}: RestaurantMenuCategoryProps) => (
  <Accordion id={id} title={title} className={className}>
    {!!items && (
      <div className="flex flex-col gap-y-5">
        {items.filter(Boolean).map(({ title, description, price }, index) => (
          <div
            key={`${id}-${index}-${title}`}
            className="flex justify-between gap-x-4 text-pageText"
          >
            <div className="flex flex-col gap-y-1.5">
              <span className="is-h4">{title}</span>
              {!!description && <span>{description}</span>}
            </div>

            {price && <span className="is-h4">{price},-</span>}
          </div>
        ))}
      </div>
    )}
  </Accordion>
)

export default RestaurantMenuCategory
