import axios, { type AxiosResponse } from 'axios'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityNewsletterBlock } from '@data/sanity/queries/types/blocks'
import {
  newsletterSignUpEventName,
  triggerGoogleTagManagerEvent,
} from '@lib/analytics'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language-context'
import { StringsContext } from '@lib/strings-context'
import { type NewsletterResponse } from '@pages/api/klaviyo/newsletter-join'

import Alert from '@components/alert'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import InputField from '@components/input-field'
import SimplePortableText from '@components/simple-portable-text'
import ComplexPortableText from '@components/complex-portable-text'

interface NewsletterProps
  extends Omit<SanityNewsletterBlock, '_key' | '_type'> {
  id: string
  className?: string
  isInlineButton?: boolean
}

interface NewsletterFormValues {
  fullname: string
  email: string
  acceptTerms?: boolean
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const Newsletter = ({
  id,
  service,
  klaviyoListID,
  variant,
  title,
  description,
  terms,
  submit,
  successMsg,
  errorMsg,
  className,
  isInlineButton,
}: NewsletterProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const { handleSubmit, register, watch, reset } =
    useForm<NewsletterFormValues>()

  const hasAgreed = terms ? watch('acceptTerms') : true
  const email = watch('email')

  // Call to reset the form
  const resetForm = (e: FormEvent) => {
    e.preventDefault()
    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const addToNewsletter = async (
    newsletterFormValues: NewsletterFormValues
  ) => {
    if (!service) {
      return
    }

    const payload = JSON.stringify({
      ...(service === 'klaviyo' && {
        listID: klaviyoListID,
      }),
      ...newsletterFormValues,
    })

    const url = `/api/${service.toLowerCase()}/newsletter-join`

    try {
      await axios.post<
        NewsletterResponse,
        AxiosResponse<NewsletterResponse>,
        string
      >(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      })

      setIsSubmitting(false)
      setIsSuccess(true)

      triggerGoogleTagManagerEvent(newsletterSignUpEventName)
    } catch (error) {
      setIsSubmitting(false)
      setIsError(true)
      console.log(error)
    }
  }

  // handle form submission
  const onSubmit = (
    newsletterFormValues: NewsletterFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    // Set an error if there is no Klaviyo list supplied
    if (service === 'klaviyo' && !klaviyoListID) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms ir required
    if (!hasAgreed && terms) {
      return
    }

    setIsSubmitting(true)
    setIsError(false)
    addToNewsletter(newsletterFormValues)
  }

  const fullnameRegister = register('fullname')
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: emailRegex,
      message: strings.emailInvalid,
    },
  })
  const acceptTermsRegister = register('acceptTerms')

  const isExtended = variant === 'extended'
  const isDisabled = isSubmitting || !hasAgreed || !emailRegex.test(email)

  if (!service) {
    return null
  }

  const form = (
    <form className={cx(className)} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence mode="wait">
        {!isError && !isSuccess && (
          <motion.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <input
              type="text"
              autoComplete="off"
              className="hidden"
              aria-hidden="true"
              onChange={fullnameRegister.onChange}
              onBlur={fullnameRegister.onBlur}
              ref={fullnameRegister.ref}
              name={fullnameRegister.name}
            />

            {isExtended ? (
              <input
                id={`newsletter-${id}-email`}
                type="email"
                onChange={emailRegister.onChange}
                onBlur={emailRegister.onBlur}
                ref={emailRegister.ref}
                name={emailRegister.name}
                placeholder={strings.emailAddress}
                className={cx(
                  'p-4 mb-5 rounded-full w-full max-w-[300px] sm:max-w-[400px] text-pageText'
                )}
              />
            ) : (
              <InputField
                id={`newsletter-${id}-email`}
                type="email"
                formRegister={emailRegister}
                placeholder={strings.emailAddress}
                className="mb-4"
              >
                {isInlineButton && (
                  <Button
                    id={`newsletter-${id}-submit`}
                    className="ml-3 text-xs whitespace-nowrap uppercase"
                    type="submit"
                    size={ButtonSize.SMALL}
                    disabled={isDisabled}
                  >
                    {submit}
                  </Button>
                )}
              </InputField>
            )}

            {terms && (
              <Checkbox
                id={`newsletter-${id}-acceptTerms`}
                formRegister={acceptTermsRegister}
                className="mb-4"
              >
                <div className="rc rc-checkbox">
                  <SimplePortableText content={terms} />
                </div>
              </Checkbox>
            )}

            {!isInlineButton && (
              <Button
                id={`newsletter-${id}-submit`}
                type="submit"
                className={cx({
                  'w-full mt-3': !isExtended,
                  'w-auto uppercase mt-1': isExtended,
                })}
                size={ButtonSize.NORMAL}
                color={ButtonColor.DEFAULT}
                variant={ButtonVariant.FILLED}
                disabled={isDisabled}
              >
                {submit}
              </Button>
            )}
          </motion.div>
        )}

        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className={cx({ 'max-w-[400px] mx-auto': isExtended })}
          >
            <Alert className="rc rc-alert">
              <ComplexPortableText content={successMsg} />
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className={cx({ 'max-w-[400px] mx-auto': isExtended })}
          >
            <Alert
              buttonText={strings.buttonTryAgain}
              onClick={resetForm}
              buttonColor={ButtonColor.DEFAULT}
              className="rc rc-alert"
            >
              <ComplexPortableText content={errorMsg} />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )

  if (isExtended) {
    return (
      <div className="bg-pageText text-pageBG py-8 xs:py-10 md:py-12 lg:py-20 px-5 xs:px-10 flex flex-col justify-center items-center rounded-md">
        {title && <h4 className="text-center mb-4 max-w-4xl">{title}</h4>}

        {description && (
          <p className="text-base text-center max-w-3xl">{description}</p>
        )}
        <div className="w-full text-center mt-8">{form}</div>
      </div>
    )
  }

  return form
}

export default Newsletter
