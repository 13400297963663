import cx from 'classnames'
import { type Variants, motion } from 'framer-motion'
import { type ReactNode, useState } from 'react'

import Icon from '@components/icon'

interface AccordionProps {
  id: string
  title?: ReactNode
  className?: string
  innerClassName?: string
  children?: ReactNode
}

const accordionContentVariants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

const Accordion = ({
  id,
  title,
  className,
  innerClassName,
  children,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={cx(className)}>
      <button
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        aria-expanded={isOpen}
        aria-controls={`accordion-${id}`}
        className={cx(
          'text-left flex justify-between items-center px-0 py-5 w-full bg-transparent border-b',
          {
            'border-red': isOpen,
            'border-borderGray': !isOpen,
          }
        )}
      >
        <div
          className={cx('is-h3', {
            'text-red': isOpen,
            'text-pageText': !isOpen,
          })}
        >
          {title}
        </div>
        <div className="ml-2 text-2xl flex items-center justify-center">
          <Icon
            id={`accordion-icon-${id}`}
            name="ChevronDown"
            viewBox="0 0 28 28"
            className={cx(
              'transition-transform duration-300 ease-custom-1 text-current',
              {
                'transform rotate-180': isOpen,
              }
            )}
          />
        </div>
      </button>

      <motion.div
        id={`accordion-${id}`}
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        exit="closed"
        variants={accordionContentVariants}
        transition={{
          duration: 0.5,
          ease: [0.19, 1.0, 0.22, 1.0],
        }}
        className="overflow-hidden"
      >
        <div
          className={cx(
            'm-0 py-8 md:py-10 max-w-[1000px] mx-auto',
            innerClassName
          )}
        >
          {children}
        </div>
      </motion.div>
    </div>
  )
}

export default Accordion
