import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'

/**
 * Counts links in a dropdown.
 */
export const getLinkCount = (dropdown: SanityMenuFeaturedLinkFragment) =>
  dropdown.dropdownItems.length

/**
 * Counts links in all active dropdowns.
 */
export const getActiveLinkCount = (
  dropdowns: SanityMenuFeaturedLinkFragment[],
  isOpen: boolean,
  activeId?: string
) =>
  dropdowns
    .map((dropdown) =>
      isOpen && activeId === dropdown._key ? getLinkCount(dropdown) : 0
    )
    .reduce((sum, linkCount) => sum + linkCount, 0)
