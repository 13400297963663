import cx from 'classnames'

import {
  type SanityLink,
  type SanityLinkFragment,
} from '@data/sanity/queries/types/link'
import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'

import LanguageSwitch from '@components/language-switch'
import CustomLink from '@components/link'
import Dropdown from './dropdown'
import DropdownButton from './dropdown-button'

export type ItemType = SanityLinkFragment | SanityMenuFeaturedLinkFragment

interface MenuItemProps {
  menuId?: string
  item: ItemType
  hasFocus: boolean
  useMegaNav: boolean
  onClick?: () => void
  isFooterMenuItem?: boolean
  isHeaderDesktopMenuItem?: boolean
  isHeaderMobilePrimaryMenuItem?: boolean
  isHeaderMobileSecondaryMenuItem?: boolean
  isMegaNavMenuItem?: boolean
}

const MenuItem = ({
  menuId,
  item,
  hasFocus,
  useMegaNav,
  onClick,
  isFooterMenuItem,
  isHeaderDesktopMenuItem,
  isHeaderMobilePrimaryMenuItem,
  isHeaderMobileSecondaryMenuItem,
  isMegaNavMenuItem,
}: MenuItemProps) => {
  const title = 'title' in item && item.title ? item.title : ''
  const isButton = 'isButton' in item && item.isButton ? item.isButton : false

  const itemClassNames = !isButton
    ? cx({
        'hover:opacity-60 transition-opacity duration-200 !no-underline': true,
        'text-left text-base': isFooterMenuItem,
        'block text-base p-2': isHeaderDesktopMenuItem,
        'block p-0 text-4xl text-left':
          isHeaderMobilePrimaryMenuItem || isHeaderMobileSecondaryMenuItem,
        'text-4xl': isHeaderMobilePrimaryMenuItem,
        'text-xl': isHeaderMobileSecondaryMenuItem,
        'inline-block text-4xl leading-normal': isMegaNavMenuItem,
      })
    : cx({
        'mx-2': isHeaderDesktopMenuItem,
      })

  // Language switch
  if (item._type === 'navLanguageSwitch') {
    return (
      <LanguageSwitch
        id={item._key}
        buttonClassName={itemClassNames}
        hasCurrency={item.displayCurrency}
      />
    )
  }

  // Dropdown list
  if (item._type === 'navDropdown') {
    if (useMegaNav) {
      return (
        <DropdownButton
          id={item._key}
          title={title}
          className={itemClassNames}
        />
      )
    }

    if (!('dropdownItems' in item) || !item.dropdownItems) {
      return null
    }

    return (
      <Dropdown
        id={item._key}
        title={title}
        items={item.dropdownItems}
        onClick={onClick}
        listItemClassName={cx({
          'mx-2 my-0': isHeaderDesktopMenuItem,
        })}
        listItemLinkClassName={itemClassNames}
        buttonClassName={itemClassNames}
      />
    )
  }

  // Single link
  const titleSlug =
    'title' in item ? item.title.toLowerCase().replace(/ /g, '-') : ''
  const link: SanityLink = {
    ...item,
    ...(menuId && {
      id: `${menuId}-${titleSlug}`,
    }),
  }

  return (
    <CustomLink
      link={link}
      onClick={onClick}
      tabIndex={!hasFocus ? -1 : 0}
      className={itemClassNames}
    >
      {title}
    </CustomLink>
  )
}

export default MenuItem
