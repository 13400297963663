import cx from 'classnames'

import { type CSSPropertiesWithHeight } from './mega-navigation'

interface MegaNavigationBackgroundProps {
  height: number
}

const MegaNavigationBackground = ({
  height,
}: MegaNavigationBackgroundProps) => {
  const style: CSSPropertiesWithHeight = {
    '--h': height,
    '--hpx': `${height}px`,
  }

  return (
    <div
      className={cx(
        'hidden lg:block',
        'before:absolute before:inset-x-0 before:z-10 before:h-px before:bg-header-bg',
        'before:will-change-transform before:origin-top-left before:scale-y-[var(--h)]',
        'before:transition-transform before:duration-[.8s] before:ease-custom-2',
        'after:absolute after:inset-x-0 after:-mt-px before:z-20',
        'after:translate-x-0 after:translate-y-[var(--hpx)]',
        'before:top-0 after:top-0'
      )}
      style={style}
    />
  )
}

export default MegaNavigationBackground
