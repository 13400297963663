import { useContext, useEffect } from 'react'
import cx from 'classnames'

import { SiteContext } from '@lib/site-context'

import Icon from '@components/icon'
import MuxVideo from '@components/video/mux-video'

const VideoModal = () => {
  const {
    videoModal: { isOpen, video },
    toggleVideoModal,
  } = useContext(SiteContext)

  useEffect(() => {
    document.body.classList.toggle('max-h-screen', isOpen)
    document.body.classList.toggle('overflow-hidden', isOpen)

    return () => {
      document.body.classList.toggle('max-h-screen', false)
      document.body.classList.toggle('overflow-hidden', false)
    }
  }, [isOpen])

  useEffect(() => {
    function handleEscapeKeyPress(event: KeyboardEvent) {
      if (event.key === 'Escape' && isOpen) {
        toggleVideoModal(false)
      }
    }

    document.addEventListener('keydown', handleEscapeKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [isOpen, toggleVideoModal])

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={cx(
        'bg-pageBG bg-opacity-90 backdrop-blur-[6px] fixed inset-0 w-full h-full py-10 overflow-y-scroll no-scrollbar z-90 flex items-center justify-center flex-wrap',
        {
          hidden: !isOpen,
          block: isOpen,
        }
      )}
    >
      <button
        onClick={() => toggleVideoModal(false)}
        className="fixed top-4 right-4 rounded-full bg-pageBG w-10 h-10 md:w-14 md:h-14 flex items-center justify-center z-10"
      >
        <Icon
          id="modal-close"
          name="Cross"
          className="text-pageText h-4 w-4 md:h-5 md:w-5"
        />
      </button>

      <div className="container max-w-[1000px]">
        {video?.muxVideo && video.type === 'mux' && (
          <MuxVideo
            video={video.muxVideo}
            showControls={video.settings?.controls}
            autoplay={video.settings?.autoplay}
            loop={video.settings?.loop}
            muted={video.settings?.muted}
            borderRadius={video.borderRadius}
          />
        )}
      </div>
    </div>
  )
}

export default VideoModal
