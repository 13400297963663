import cx from 'classnames'

import { type SanityRestaurantCoursesStrings } from '@data/sanity/queries/types/modules'

interface RestaurantCourseCardProps {
  title?: string
  pricePerPerson?: number
  items?: string[]
  itemsLabel?: string
  strings: SanityRestaurantCoursesStrings
  className?: string
}

const RestaurantCourseCard = ({
  title,
  pricePerPerson,
  items,
  strings,
  itemsLabel = strings.courseItemListLabel,
  className,
}: RestaurantCourseCardProps) => {
  return (
    <div
      className={cx('border-2 border-red px-5 md:px-6 lg:px-8 py-2', className)}
    >
      <div className="flex justify-between gap-x-4 py-4 lg:py-6">
        <h3>{title}</h3>

        {pricePerPerson && (
          <div className="inline-flex flex-col items-end">
            <span className="is-h3">{pricePerPerson},-</span>
            <span className="text-xs">{strings.perPerson}</span>
          </div>
        )}
      </div>

      {items && items.length > 0 && (
        <div className="flex flex-col gap-y-1.5 border-t border-borderGray py-4 lg:py-6">
          {itemsLabel && <span className="font-bold mb-1">{itemsLabel}</span>}

          {items.filter(Boolean).map((item, index) => (
            <span key={`${index}-${item}`}>{item}</span>
          ))}
        </div>
      )}
    </div>
  )
}

export default RestaurantCourseCard
