import cx from 'classnames'

interface HamburgerProps {
  isOpened?: boolean
  className?: string
}

const Hamburger = ({ isOpened, className }: HamburgerProps) => (
  <span
    className={cx(
      'relative flex flex-col items-center justify-center mx-auto w-7',
      {
        'mt-[-3px]': isOpened,
      },
      className
    )}
  >
    <span
      className={cx(
        'block relative w-full h-0 border-t border-current my-[6px] transition-transform duration-[.2s]',
        {
          'transform translate-y-[5px] opacity-0': isOpened,
        }
      )}
    />

    {/* Icon */}
    <span
      className={cx('block relative w-full h-0 border-t border-current', {
        'border-transparent': isOpened,
      })}
    >
      <span
        className={cx(
          'block absolute w-full h-0 border-t border-current top-[calc(50%-.5px)] transition-transform duration-[.2s]',
          {
            'opacity-0': !isOpened,
            'opacity-100 transform rotate-45': isOpened,
          }
        )}
      />
      <span
        className={cx(
          'block absolute w-full h-0 border-t border-current top-[calc(50%-.5px)] transition-transform duration-[.2s]',
          {
            'opacity-0': !isOpened,
            'opacity-100 transform -rotate-45': isOpened,
          }
        )}
      />
    </span>

    <span
      className={cx(
        'block relative w-full h-0 border-t border-current my-[6px] transition-transform duration-[.2s]',
        {
          'transform translate-y-[-5px] opacity-0': isOpened,
        }
      )}
    />
  </span>
)

export default Hamburger
