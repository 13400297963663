export interface ErrorMessages {
  [key: string]: string
}

/**
 * Checks if an object is found in another array of objects.
 */
export function hasObject(records?: any[], values?: any[] | any) {
  if (!records) {
    return false
  }

  return records.some((object) => {
    for (const key in object) {
      if (
        values &&
        key in values &&
        object[key] != (values as Record<string, unknown>)[key]
      ) {
        return false
      }
    }

    return true
  })
}

/**
 * Keeps a number within a range.
 */
export function clampRange(value: number, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value
}

/**
 * Formats a value by adding thousands separators.
 */
export const addThousandSeparators = (
  value: string,
  thousandSeparator: string
) => {
  if (!thousandSeparator) {
    return value
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
}

/**
 * Gets price from value in minor units and adds tax. Adds trailing zeros if needed.
 */
export const getPrice = (
  minorUnits: number,
  taxRate: number,
  hasTrailingZeros = false,
  thousandSeparator = ','
) => {
  const price = (minorUnits / 100) * (1 + taxRate)

  if (!hasTrailingZeros && price % 1 === 0) {
    return addThousandSeparators(`${price}`, thousandSeparator)
  }

  const parts = price.toFixed(2).split('.')
  parts[0] = addThousandSeparators(parts[0], thousandSeparator)

  return `${parts.join('.')}`
}

/**
 * Parses required page request parameter.
 */
export function parseRequiredParameter<T = string>(parameter: T | T[]): T {
  return Array.isArray(parameter) ? parameter[0] : parameter
}

/**
 * Parses optional page request parameter.
 */
export function parseOptionalParameter<T = string>(
  parameter?: T | T[] | null
): T | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameter<T>(parameter)
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(parameter: T | T[]): T[] {
  return Array.isArray(parameter) ? parameter : [parameter]
}

/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: T | T[] | null
): T[] | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Compares numbers for sorting.
 */
export const compareNumbers = (number1: number, number2: number) =>
  number1 > number2 ? 1 : number1 < number2 ? -1 : 0

/**
 * Compares strings for sorting.
 */
export const compareStrings = (string1: string, string2: string) =>
  string1.localeCompare(string2)

/**
 * Determines if 2 variables are equal using JSON representation.
 */
export const isEqual = (variable1: unknown, variable2: unknown) =>
  JSON.stringify(variable1) === JSON.stringify(variable2)
