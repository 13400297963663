import { useContext } from 'react'

import {
  type SanityBlogPostWithoutBody,
  type SanityBlogPostOptions,
} from '@data/sanity/queries/types/blog'
import { getFormattedDate } from '@lib/format'
import { LanguageContext } from '@lib/language-context'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

interface BlogPostCardProps {
  post: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

const BlogPostCard = ({ post, options }: BlogPostCardProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  if (!post) {
    return null
  }

  return (
    <SimpleLink
      href={getLinkPageUrl('blogPost', post.slug.current)}
      className="cursor-pointer"
    >
      {post.coverImage && options?.showCoverImage && (
        <Photo
          className="mb-5"
          image={{
            ...post.coverImage,
            customRatio: 1.7777777778,
            borderRadius: 'lg',
          }}
        />
      )}

      <h3 className="mb-4">{post.title}</h3>

      {options?.showExcerpt && <p className="mb-6">{post.excerpt}</p>}

      {options?.showCategories && (
        <div className="flex flex-wrap mb-4">
          {post.categories?.map((category) => (
            <SimpleLink
              key={`${post.slug.current}-category-${category.slug.current}`}
              href={getLinkPageUrl('blogCategory', category.slug.current)}
              className="mr-2 text-[12px] opacity-50"
            >
              {category.name}
            </SimpleLink>
          ))}
        </div>
      )}

      {(options?.showAuthorPhoto ||
        options?.showAuthorName ||
        options?.showDate) && (
        <div className="flex items-center">
          {options?.showAuthorPhoto && post.author?.photo && (
            <Photo
              image={post.author?.photo}
              width={40}
              height={40}
              className="rounded-full overflow-hidden shrink-0 w-[40px] h-[40px] mr-2"
              imageClassName="object-cover"
            />
          )}
          <div>
            {options?.showAuthorName && (
              <p className="text-sm">
                {strings.blogPostBy}{' '}
                <SimpleLink
                  href={getLinkPageUrl(
                    'blogAuthor',
                    post.author?.slug?.current
                  )}
                >
                  {post.author?.name}
                </SimpleLink>
                {options?.showDate
                  ? `, ${getFormattedDate(
                      post.date ?? post._createdAt,
                      locale
                    )}`
                  : ''}
              </p>
            )}
          </div>
        </div>
      )}
    </SimpleLink>
  )
}

export default BlogPostCard
