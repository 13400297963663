import cx from 'classnames'
import { type ButtonHTMLAttributes, forwardRef, useId } from 'react'

import {
  type SanityButtonColor,
  type SanityButtonIconAlignment,
  type SanityButtonSize,
  type SanityButtonVariant,
} from '@data/sanity/queries/types/link'

import Icon, { IconName } from '@components/icon'

export enum ButtonVariant {
  LINK = 'link',
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum ButtonColor {
  INHERIT = 'inherit',
  DEFAULT = 'default',
  WHITE = 'white',
}

export enum ButtonIconAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ButtonIconSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

type VariantColorClassMap = Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
>

export type SizeClassMap = Record<ButtonSize, string | string[]>

export type ColorClassMap = Record<ButtonColor, string | string[]>

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: IconName
  iconSize?: ButtonIconSize
  iconAlignment?: ButtonIconAlignment
  isActive?: boolean
}

export const getBaseClasses = (
  variant?: ButtonVariant,
  color?: ButtonColor,
  hasIcon?: boolean,
  hasThinBorder?: boolean
) =>
  cx('inline-flex items-center disabled:opacity-50 transition-all', {
    [`justify-center font-medium text-center leading-[1.2] ${
      hasThinBorder ? 'border' : 'border-2'
    }`]: variant !== ButtonVariant.LINK,
    'underline hover:no-underline': variant === ButtonVariant.LINK && !hasIcon,
    'font-normal hover:opacity-60': variant === ButtonVariant.LINK && hasIcon,
    'hover:opacity-80':
      variant === ButtonVariant.FILLED ||
      (variant === ButtonVariant.OUTLINED && color !== ButtonColor.WHITE),
  })

export const colorClasses: VariantColorClassMap = {
  [ButtonVariant.FILLED]: {
    [ButtonColor.WHITE]: ['border-white bg-white text-red'],
    [ButtonColor.DEFAULT]: [
      'bg-btn-filled-bg text-btn-filled-text border-btn-filled-border',
      'hover:bg-btn-filled-bg-hover hover:text-btn-filled-text-hover hover:border-btn-filled-border-hover',
    ],
    [ButtonColor.INHERIT]: [],
  },
  [ButtonVariant.OUTLINED]: {
    [ButtonColor.WHITE]: [
      'bg-transparent border-white text-white border-opacity-25',
      'hover:bg-transparent hover:border-white hover:border-opacity-50 hover:text-white',
    ],
    [ButtonColor.DEFAULT]: [
      'bg-btn-outlined-bg border border-btn-outlined-border text-btn-outlined-text',
      'hover:bg-btn-outlined-bg-hover hover:text-btn-outlined-text-hover hover:border-btn-outlined-border-hover',
    ],
    [ButtonColor.INHERIT]: ['bg-transparent border-current text-current'],
  },
  [ButtonVariant.LINK]: {
    [ButtonColor.WHITE]: ['text-white'],
    [ButtonColor.DEFAULT]: ['text-current'],
    [ButtonColor.INHERIT]: ['text-current'],
  },
}

export const textColorClasses: ColorClassMap = {
  [ButtonColor.WHITE]: 'text-white',
  [ButtonColor.DEFAULT]: 'text-current',
  [ButtonColor.INHERIT]: 'text-current',
}

const sizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'text-sm',
  [ButtonSize.NORMAL]: 'text-[0.9375rem]',
  [ButtonSize.LARGE]: 'text-xl',
}

const spacingClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'px-3 py-2',
  [ButtonSize.NORMAL]: 'px-5 py-3',
  [ButtonSize.LARGE]: 'px-7 py-3',
}

export const iconSizeClasses: SizeClassMap = {
  [ButtonIconSize.DEFAULT]: '',
  [ButtonIconSize.SMALL]: 'text-sm',
  [ButtonIconSize.NORMAL]: 'text-[0.9375rem]',
  [ButtonIconSize.LARGE]: 'text-xl',
}

const activeClasses: VariantColorClassMap = {
  [ButtonVariant.FILLED]: {
    [ButtonColor.WHITE]: 'border-white bg-white text-red',
    [ButtonColor.DEFAULT]:
      'bg-btn-filled-bg-hover !text-btn-filled-text-hover border-btn-filled-border-hover',
    [ButtonColor.INHERIT]: '',
  },
  [ButtonVariant.OUTLINED]: {
    [ButtonColor.WHITE]: 'border-white bg-white !text-red',
    [ButtonColor.DEFAULT]:
      'bg-btn-outlined-bg-hover !text-btn-outlined-text-hover border-btn-outlined-border-hover',
    [ButtonColor.INHERIT]:
      'bg-transparent border-current text-current opacity-60',
  },
  [ButtonVariant.LINK]: colorClasses[ButtonVariant.LINK],
}

export const getButtonVariant = (variant?: SanityButtonVariant) => {
  if (!variant) {
    return
  }

  return variant as ButtonVariant
}

export const getButtonSize = (size?: SanityButtonSize) => {
  if (!size) {
    return ButtonSize.DEFAULT
  }

  return size as ButtonSize
}

export const getButtonColor = (color?: SanityButtonColor) => {
  if (!color) {
    return
  }

  return color as ButtonColor
}

export const getButtonIconAlignment = (
  iconAlignment?: SanityButtonIconAlignment
) => {
  if (!iconAlignment) {
    return
  }

  return iconAlignment as ButtonIconAlignment
}

export const getButtonStyles = ({
  variant = ButtonVariant.LINK,
  color = ButtonColor.DEFAULT,
  size = ButtonSize.NORMAL,
  isActive,
  icon,
}: ButtonProps) => {
  // Using optional chaining operators below because Sanity could contain a deprecated variant, color or size
  return cx(
    getBaseClasses(variant, color, Boolean(icon), true),
    colorClasses[variant]?.[color],
    isActive ? activeClasses[variant]?.[color] : '',
    variant !== ButtonVariant.LINK ? spacingClasses[size] : '',
    sizeClasses[size]
  )
}

export interface ButtonIconProps {
  name: IconName
  alignment?: ButtonIconAlignment
  size?: ButtonIconSize | ButtonSize
  className?: string
}

export const ButtonIcon = ({
  name,
  alignment = ButtonIconAlignment.RIGHT,
  size,
  className,
}: ButtonIconProps) => {
  const id = useId()

  return (
    <span
      className={cx(
        alignment === ButtonIconAlignment.LEFT
          ? [
              'order-first',
              {
                'mr-2': size !== ButtonIconSize.LARGE,
                'mr-3': size === ButtonIconSize.LARGE,
              },
            ]
          : '',
        alignment === ButtonIconAlignment.RIGHT
          ? {
              'ml-2': size !== ButtonIconSize.LARGE,
              'ml-3': size === ButtonIconSize.LARGE,
            }
          : '',
        className,
        name === 'ArrowRightCircle' && '!text-[200%]' // TODO: Future me or someone else, you can do it better!
      )}
    >
      <Icon id={`button-icon-${id}`} name={name} />
    </span>
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onBeforeInput,
      id,
      style,
      type,
      'aria-label': ariaLabel,
      variant,
      size,
      color,
      icon,
      iconSize,
      iconAlignment,
      isActive,
    }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
    ref
  ) => {
    return (
      <button
        type={type}
        id={id}
        ref={ref}
        className={cx(
          'group',
          getButtonStyles({ variant, size, color, isActive, icon }),
          className
        )}
        onClick={onClick}
        disabled={disabled}
        style={style}
        onBeforeInput={onBeforeInput}
        aria-label={ariaLabel}
      >
        {children}
        {icon && (
          <ButtonIcon
            name={icon}
            alignment={iconAlignment}
            size={iconSize ?? size}
            className={cx(
              iconSize
                ? iconSizeClasses[iconSize]
                : size
                ? iconSizeClasses[size]
                : {}
            )}
          />
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
