import Cookies from 'js-cookie'
import { type Variants, AnimatePresence, motion } from 'framer-motion'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useIsFirstRender } from 'usehooks-ts'

import { type SanityCookieSettings } from '@data/sanity/queries/types/site'
import { StringsContext } from '@lib/strings-context'

import Button, { ButtonColor, ButtonVariant } from '@components/buttons/button'
import CustomLink from '@components/link'

interface AcceptCookies {
  acceptedCookies: boolean
  onAcceptCookies: () => void
}

type CookieBarProps = SanityCookieSettings

const barAnimation: Variants = {
  show: {
    y: '0%',
    transition: {
      duration: 0.6,
      ease: [0.16, 1, 0.3, 1],
    },
  },
  hide: {
    y: '100%',
    transition: {
      duration: 0.6,
      ease: [0.16, 1, 0.3, 1],
    },
  },
}

const useAcceptCookies = (cookieName = 'accept_cookies'): AcceptCookies => {
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  useEffect(() => {
    if (!Cookies.get(cookieName)) {
      setAcceptedCookies(false)
    }
  }, [cookieName])

  return {
    acceptedCookies,
    onAcceptCookies: () => {
      setAcceptedCookies(true)
      Cookies.set(cookieName, 'accepted', { expires: 365 })
    },
  }
}

const CookieBar = ({ enabled, message, link }: CookieBarProps) => {
  const strings = useContext(StringsContext)

  const isFirstRender = useIsFirstRender()
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()

  if (!enabled || isFirstRender || !message || acceptedCookies) {
    return null
  }

  return (
    <AnimatePresence>
      <motion.div
        initial="show"
        animate="show"
        exit="hide"
        variants={barAnimation}
        role="dialog"
        aria-live="polite"
        className="fixed bottom-0 right-0 z-90 p-4 w-full max-w-3xl"
      >
        <div className="grid gap-6 sm:flex items-center p-6 pb-4 sm:p-6 rounded-lg bg-pageText text-pageBG">
          <div className="flex-1 sm:pr-8 text-center sm:text-left">
            <p className="text-sm leading-normal">
              {message.split('\n').map((text: string, index: number) => (
                <Fragment key={index}>
                  {text}
                  {message.split('\n')[index + 1] && <br />}
                </Fragment>
              ))}
            </p>
          </div>

          <div className="flex justify-center flex-wrap items-center gap-x-8 gap-y-4">
            {link && (
              <CustomLink link={{ page: link }}>
                {strings.buttonLearnMore}
              </CustomLink>
            )}

            <Button
              onClick={() => onAcceptCookies()}
              color={ButtonColor.WHITE}
              variant={ButtonVariant.FILLED}
            >
              {strings.buttonAccept}
            </Button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default CookieBar
