import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { motion } from 'framer-motion'
import { type RefObject, useContext, useEffect, useState } from 'react'
import { useElementSize, useIntersectionObserver } from 'usehooks-ts'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityHeaderSettings } from '@data/sanity/queries/types/site'
import { type SanityColorValue } from '@data/sanity/queries/types/color'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import Menu from '@blocks/navigation/menu'
import MegaNavigation from '@blocks/navigation/mega-navigation'
import { gridColorClasses } from '@modules/grid'
import PromoBar from '../promo-bar'
import Hamburger from './hamburger'
import Logo from './logo'

export interface HeaderSizeValues {
  height: number
}

type HeaderProps = SanityHeaderSettings & {
  pageBackground?: SanityColorValue
  isTransparent: boolean
  topObserverRef: RefObject<Element>
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
  onResize?: (newValues: HeaderSizeValues) => void
}

const Header = ({
  promo,
  menuDesktopLeft,
  menuDesktopRight,
  menuMobilePrimary,
  menuMobileSecondary,
  pageBackground,
  isTransparent,
  logo,
  invertedLogo,
  topObserverRef,
  onResize,
}: HeaderProps) => {
  const strings = useContext(StringsContext)
  const { megaNavigation, mobileMenu, toggleMegaNavigation, toggleMobileMenu } =
    useContext(SiteContext)

  const [isLoaded, setIsLoaded] = useState(false)

  const entry = useIntersectionObserver(topObserverRef, {
    freezeOnceVisible: false,
    threshold: 1,
  })
  const isInView = !!entry?.isIntersecting
  const isObserverVisible = isLoaded ? isInView : isTransparent

  const [headerRef, headerRectangle] = useElementSize()

  useEffect(() => {
    // Wait for intersection observer to load, then show header
    if (!isLoaded) {
      setTimeout(() => setIsLoaded(true), 200)
    }

    if (isLoaded && headerRectangle && onResize) {
      onResize({
        height: headerRectangle.height,
      })
    }
  }, [headerRectangle, isLoaded, onResize])

  const colorClasses = pageBackground
    ? gridColorClasses[pageBackground]
    : 'bg-pageBG text-pageText'

  return (
    <>
      <a
        href="#content"
        className="block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs uppercase focus:translate-y-0 focus:outline-none"
      >
        {strings.skipToContent}
      </a>

      {promo?.enabled && (
        <PromoBar
          enabled={promo.enabled}
          display={promo.display}
          text={promo.text}
          link={promo.link}
        />
      )}

      <header
        className={cx('sticky top-0 inset-x-0 z-50', {
          'h-0': isTransparent,
        })}
      >
        <div ref={headerRef} className="relative">
          <div
            className={cx(
              'relative py-1 sm:py-4 md:py-4 z-30 transition-all duration-300',
              colorClasses,
              {
                'bg-header-bg': !isTransparent,
                'bg-transparent text-pageText':
                  isTransparent && isObserverVisible,
                'bg-header-bg bg-opacity-95':
                  isTransparent && !isObserverVisible && isLoaded,
                'text-header-text delay-75':
                  isTransparent && isObserverVisible && !megaNavigation?.isOpen,
              }
            )}
          >
            <div className="px-4 lg:container flex flex-row justify-between items-center w-full z-30 min-h-[50px]">
              {/* Logo */}
              <Logo
                logo={logo}
                invertedLogo={invertedLogo}
                isInvertedLogo={isTransparent && isObserverVisible}
                className="z-60"
              />

              {/* Mobile Header Menu */}
              <nav
                id="mobile-nav"
                className={cx('lg:hidden flex justify-end items-center')}
              >
                <FocusTrap active={mobileMenu.isOpen}>
                  <div className="flex items-center">
                    {/* Menu toggle */}
                    <button
                      onClick={() => toggleMobileMenu(!mobileMenu.isOpen)}
                      aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
                      aria-controls="mobile-nav"
                      aria-label={strings.mobileMenuLabel}
                      className={cx(
                        'lg:hidden relative z-60 w-12 h-12 appearance-none no-underline cursor-pointer font-inherit rounded-full border border-darkGray',
                        {
                          'text-pageText': mobileMenu.isOpen,
                          'transition-colors duration-300': isTransparent,
                        }
                      )}
                    >
                      <Hamburger
                        isOpened={mobileMenu.isOpen}
                        className="border-red"
                      />
                    </button>

                    <motion.div
                      initial="hide"
                      animate={mobileMenu.isOpen ? 'show' : 'hide'}
                      variants={{
                        show: {
                          x: '0%',
                        },
                        hide: {
                          x: '-100%',
                        },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.16, 1, 0.3, 1],
                      }}
                      className={cx(
                        'fixed top-0 left-0 w-full h-screen z-50 flex flex-col bg-white text-pageText'
                      )}
                    >
                      <div className="flex-1 flex flex-col overflow-y-scroll px-4 pb-4 pt-[calc(var(--headerBottom,10rem)+1.5rem)] no-scrollbar">
                        {!!menuMobilePrimary?.items && (
                          <div>
                            <Menu
                              id={menuMobilePrimary.slug.current}
                              items={menuMobilePrimary.items}
                              onClick={() => toggleMobileMenu(false)}
                              isHeaderMobilePrimaryMenu
                            />
                          </div>
                        )}

                        {!!menuMobileSecondary?.items && (
                          <div className="pt-6">
                            <Menu
                              id={menuMobileSecondary.slug.current}
                              items={menuMobileSecondary.items}
                              onClick={() => toggleMobileMenu(false)}
                              isHeaderMobileSecondaryMenu
                            />
                          </div>
                        )}
                      </div>
                    </motion.div>

                    <div
                      className={cx(
                        'fixed inset-0 z-40 bg-backdrop bg-opacity-40 pointer-events-none transition-opacity duration-150 ease-linear',
                        {
                          'opacity-0': !mobileMenu.isOpen,
                          'pointer-events-auto backdrop-filter-blur-[6px] opacity-100':
                            mobileMenu.isOpen,
                        }
                      )}
                      onClick={() => toggleMobileMenu(false)}
                      role="presentation"
                    />
                  </div>
                </FocusTrap>
              </nav>

              <div className="hidden lg:flex items-center gap-x-4">
                {/* Desktop Header Menu */}
                {!!menuDesktopLeft?.items && (
                  <nav className={cx('flex items-center justify-center py-2')}>
                    <Menu
                      id={menuDesktopLeft.slug.current}
                      items={menuDesktopLeft.items}
                      onClick={() => toggleMegaNavigation(false)}
                      useMegaNav
                      isHeaderDesktopMenu
                    />
                  </nav>
                )}

                <nav className="flex items-center relative z-1">
                  {!!menuDesktopRight?.items && (
                    <Menu
                      id={menuDesktopRight.slug.current}
                      items={menuDesktopRight.items}
                      onClick={() => toggleMegaNavigation(false)}
                      useMegaNav
                      isHeaderDesktopMenu
                    />
                  )}
                </nav>
              </div>
            </div>
          </div>

          <MegaNavigation
            items={[
              ...(menuDesktopLeft?.items ?? []),
              ...(menuDesktopRight?.items ?? []),
            ]}
            headerHeight={headerRectangle?.height ?? 0}
          />
        </div>
      </header>
    </>
  )
}

export default Header
