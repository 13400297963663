import {
  type SanityContentCarouselBlock,
  type SanityContentCarouselItem,
} from '@data/sanity/queries/types/blocks'

import Carousel from '@components/carousel'
import BlogPostCard from '@blocks/blog/blog-post-card'
import Freeform from '@blocks/freeform'
import Photo from '@components/photo'
import MuxVideo from '@components/video/mux-video'

interface ContentCarouselItemProps {
  item: SanityContentCarouselItem
}

type ContentCarouselProps = Pick<SanityContentCarouselBlock, 'items'>

const ContentCarouselItem = ({ item }: ContentCarouselItemProps) => {
  switch (item._type) {
    case 'blogPost': {
      return (
        <BlogPostCard
          post={item.blogPost}
          options={{
            showDate: true,
          }}
        />
      )
    }

    case 'freeform': {
      return (
        <Freeform
          content={item.content}
          textAlign={item.textAlign}
          maxWidth={item.maxWidth}
        />
      )
    }

    case 'photo': {
      const image = item

      return (
        <Photo
          image={{
            ...image,
            borderRadius: image.borderRadius ?? 'xl',
            customRatio: image.customRatio ?? 5 / 7,
            _type: 'figure',
          }}
        />
      )
    }

    case 'video': {
      const video = item

      if (video.muxVideo && video.type === 'mux') {
        return (
          <MuxVideo
            video={video.muxVideo}
            showControls={video.settings?.controls}
            autoplay={video.settings?.autoplay}
            loop={video.settings?.loop}
            muted={video.settings?.muted}
            borderRadius={video.borderRadius ?? 'xl'}
            customAspectRatio={{
              custom: true,
              mobile: video.aspectRatio?.custom
                ? video.aspectRatio.mobile
                : '5:7',
              desktop: video.aspectRatio?.custom
                ? video.aspectRatio.desktop
                : '5:7',
            }}
          />
        )
      }

      return null
    }
  }
}

const ContentCarousel = ({ items }: ContentCarouselProps) => {
  return (
    <Carousel
      options={{
        selector: '.content-slide',
      }}
    >
      {items?.map((item, index) => {
        return (
          <div className="content-slide" key={index}>
            <ContentCarouselItem item={item} />
          </div>
        )
      })}
    </Carousel>
  )
}

export default ContentCarousel
