import cx from 'classnames'

import { type SanityRestaurantMenuBlock } from '@data/sanity/queries/types/blocks'

import RestaurantCourseCard from '@components/restaurant-course-card'
import RestaurantMenuCategory from '@components/restaurant-menu-category'

type RestaurantMenuProps = Pick<
  SanityRestaurantMenuBlock,
  'heading' | 'showCourses' | 'showMenu' | 'strings' | 'courses' | 'categories'
> & {
  className?: string
}

const RestaurantMenu = ({
  heading,
  showCourses,
  strings,
  courses,
  categories,
  className,
}: RestaurantMenuProps) => {
  return (
    <div
      className={cx(
        'flex flex-col gap-y-8 md:gap-y-10 text-pageText',
        className
      )}
    >
      {!!heading && <h2>{heading}</h2>}

      {!!showCourses && !!courses && (
        <div className="flex flex-col md:grid md:grid-cols-2 gap-4 md:gap-6">
          {courses
            .filter(Boolean)
            .map(({ _id, servingCount, pricePerPerson, items }) => (
              <RestaurantCourseCard
                key={_id}
                title={`${servingCount} ${
                  servingCount === 1
                    ? strings.servingSingular
                    : strings.servingPlural
                }`}
                pricePerPerson={pricePerPerson}
                items={items}
                strings={strings}
              />
            ))}
        </div>
      )}

      {categories && (
        <div className="text-red">
          {categories.filter(Boolean).map(({ name, items }, index) => {
            const id = `${index}-${name}`

            return (
              <RestaurantMenuCategory
                key={id}
                id={id}
                title={name}
                items={items}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default RestaurantMenu
