import cx from 'classnames'

import { type SanityFreeformBlock } from '@data/sanity/queries/types/blocks'

import ComplexPortableText from '@components/complex-portable-text'

type FreeformProps = Pick<
  SanityFreeformBlock,
  'content' | 'maxWidth' | 'textAlign'
>

const Freeform = ({ maxWidth, textAlign, content }: FreeformProps) => {
  if (!content) {
    return null
  }

  return (
    <div className={cx('rc', maxWidth, textAlign)}>
      <ComplexPortableText content={content} />
    </div>
  )
}

export default Freeform
