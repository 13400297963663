import axios, { type AxiosResponse } from 'axios'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import {
  type SanityContactFormBlock,
  type SanityContactFormService,
} from '@data/sanity/queries/types/blocks'
import { contactEventName, triggerGoogleTagManagerEvent } from '@lib/analytics'
import { fadeAnimation } from '@lib/animate'
import { type ContactFormValues, type ContactResponse } from '@lib/contact'
import { type Locale } from '@lib/language'
import { LanguageContext } from '@lib/language-context'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import ComplexPortableText from '@components/complex-portable-text'
import InputField from '@components/input-field'
import SimplePortableText from '@components/simple-portable-text'
import TextArea from '@components/textarea'

type ContactFormProps = Pick<
  SanityContactFormBlock,
  | 'service'
  | 'heading'
  | 'text'
  | 'submitSuccessMessage'
  | 'submitErrorMessage'
  | 'customFormStrings'
> & {
  id: string
  className?: string
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const submitContactForm = async (
  service: SanityContactFormService,
  locale: Locale,
  values: ContactFormValues
) => {
  try {
    if (!service) {
      throw new Error('Contact form submit failed, missing service')
    }

    const payload = JSON.stringify(values)
    const response = await axios.post<
      ContactResponse,
      AxiosResponse<ContactResponse>,
      string
    >(`/api/${service.toLowerCase()}/contact`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'X-Locale': locale,
      },
    })

    if (response.data && 'error' in response.data) {
      throw new Error(
        typeof response.data.error === 'string'
          ? response.data.error
          : response.data.error.message
      )
    }

    triggerGoogleTagManagerEvent(contactEventName)

    return true
  } catch (error) {
    console.log(error)
  }

  return false
}

const ContactForm = ({
  service,
  heading,
  text,
  submitSuccessMessage,
  submitErrorMessage,
  customFormStrings,
  id,
  className,
}: ContactFormProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ContactFormValues>()

  const resetForm = (event: FormEvent) => {
    event?.preventDefault()

    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }
  const onSubmit = (values: ContactFormValues, event?: BaseSyntheticEvent) => {
    event?.preventDefault()

    const asyncOnSubmit = async () => {
      setIsError(false)
      setIsSuccess(false)
      setIsSubmitting(true)

      const submitContactSuccess = await submitContactForm(
        service,
        locale,
        values
      )

      setIsError(!submitContactSuccess)
      setIsSuccess(submitContactSuccess)
      setIsSubmitting(false)
    }

    asyncOnSubmit()
  }

  const nameRegister = register('name', {
    required: customFormStrings?.fullNameMissing ?? strings.fullNameMissing,
  })
  const emailRegister = register('email', {
    required: customFormStrings?.emailMissing ?? strings.emailMissing,
    pattern: {
      value: emailRegex,
      message: customFormStrings?.emailInvalid ?? strings.emailInvalid,
    },
  })
  const phoneNumberRegister = register('phoneNumber', {
    required:
      customFormStrings?.phoneNumberMissing ?? strings.phoneNumberMissing,
  })
  const messageRegister = register('message', {
    required: customFormStrings?.messageMissing ?? strings.messageMissing,
  })

  if (!service) {
    return null
  }

  const isDisabled = isSubmitting

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      {heading && <h2>{heading}</h2>}
      {text && (
        <SimplePortableText
          content={text}
          className={cx({
            'mt-5': !!heading,
          })}
        />
      )}

      <AnimatePresence mode="wait">
        {!isError && !isSuccess && (
          <motion.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <InputField
              id={`contact-form-${id}-name`}
              type="text"
              formRegister={nameRegister}
              placeholder={
                customFormStrings?.fullNamePlaceholder ??
                strings.fullNamePlaceholder
              }
              errorMessage={errors.name?.message}
              className="mt-10"
            />
            <InputField
              id={`contact-form-${id}-email`}
              type="email"
              formRegister={emailRegister}
              placeholder={
                customFormStrings?.emailAddressPlaceholder ??
                strings.emailAddressPlaceholder
              }
              errorMessage={errors.email?.message}
              className="mt-10"
            />
            <InputField
              id={`contact-form-${id}-phone-number`}
              type="tel"
              formRegister={phoneNumberRegister}
              placeholder={
                customFormStrings?.phoneNumberPlaceholder ??
                strings.phoneNumberPlaceholder
              }
              errorMessage={errors.phoneNumber?.message}
              className="mt-10"
            />
            <TextArea
              id={`contact-form-${id}-message`}
              formRegister={messageRegister}
              placeholder={
                customFormStrings?.messagePlaceholder ??
                strings.messagePlaceholder
              }
              errorMessage={errors.message?.message}
              className="mt-10"
            />

            <Button
              id={`contact-form-${id}-submit`}
              type="submit"
              size={ButtonSize.NORMAL}
              color={ButtonColor.DEFAULT}
              variant={ButtonVariant.FILLED}
              disabled={isDisabled}
              className="mt-8"
            >
              {customFormStrings?.buttonSubmit ?? strings.buttonSubmit}
            </Button>
          </motion.div>
        )}

        {isSuccess && !isError && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert className="rc rc-alert mt-10">
              <ComplexPortableText content={submitSuccessMessage} />
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert
              buttonText={
                customFormStrings?.buttonTryAgain ?? strings.buttonTryAgain
              }
              onClick={resetForm}
              buttonColor={ButtonColor.DEFAULT}
              className="rc rc-alert mt-10"
            >
              <ComplexPortableText content={submitErrorMessage} />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default ContactForm
