import cx from 'classnames'
import dynamic from 'next/dynamic'
import { useRef } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

import {
  type SanityMuxVideo,
  type SanityVideoAspectRatio,
} from '@data/sanity/queries/types/video'
import { type SanityBorderRadius } from '@data/sanity/queries/types/image'
import { borderRadiusClassMap } from '@lib/border'
import {
  type MuxPlayerProps,
  aspectRatioPadding,
  getMuxVideoAspectRatio,
} from '@lib/video'

const MuxPlayer = dynamic(() => import('./mux-player'))

type MuxVideoProps = Omit<MuxPlayerProps, 'assetDocument'> & {
  video: SanityMuxVideo
  customAspectRatio?: SanityVideoAspectRatio
  borderRadius?: SanityBorderRadius
}

const MuxVideo = ({
  video,
  autoplay,
  showControls,
  muted,
  loop,
  customAspectRatio,
  borderRadius,
}: MuxVideoProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(containerRef, {
    freezeOnceVisible: true,
    threshold: 0.1,
  })
  const isInView = !!entry?.isIntersecting

  const videoAspectRatio = getMuxVideoAspectRatio(video)

  return (
    <div
      ref={containerRef}
      className={cx(
        'relative w-full z-0 h-full overflow-hidden',
        borderRadius ? borderRadiusClassMap[borderRadius] : '',
        customAspectRatio?.custom
          ? `${aspectRatioPadding[customAspectRatio.mobile]}`
          : '',
        customAspectRatio?.custom
          ? `sm:${aspectRatioPadding[customAspectRatio.desktop]}`
          : ''
      )}
      style={{
        paddingTop: customAspectRatio?.custom
          ? undefined
          : `${100 / videoAspectRatio}%`,
      }}
    >
      {isInView && (
        <MuxPlayer
          assetDocument={video.asset}
          showControls={showControls}
          autoplay={autoplay}
          muted={muted}
          loop={loop}
          playsInline={autoplay && !showControls}
          videoStyle={{
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </div>
  )
}

export default MuxVideo
